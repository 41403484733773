<template>
  <v-form ref="form">
    <filtro-rapido v-model="abrirFiltro"
                   ref="filtrosRapidos"
                   id="ApuracaoContratoCancelarLoteFiltros__filtro_rapido"
                   v-if="carregouMetadados && carregouFiltrosPadroes"
                   :campos-formulario="camposFormulario"
                   :metadados="metadadosAgrupado"
                   :ordenacao-campos="ordenacaoCampos"
                   grupo-expandido="Contrato"
                   :setar-filtro-padrao-ao-limpar="true"
                   :filtro-padrao="filtroPadrao"
                   @FiltroRapido__AplicaFiltros="aplicarFiltros"
                   @FiltroRapido_conteinerCarregado="$emit('ApuracaoContratoCancelarLoteFiltros_conteinerCarregado')">
    </filtro-rapido>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../../shared-components/filtro-rapido/FiltroRapido';
import ApuracaoContratoFiltroCampos from '../ApuracaoContratoFiltroCampos';

export default {
  name: 'ApuracaoContratoCancelarLoteIndustriaFiltros',
  components: { FiltroRapido },
  props: {
    value: false,
  },
  mixins: [
    ApuracaoContratoFiltroCampos,
  ],
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.apuracaoConfiguracao(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      apuracaoContratoResource: this.$api.apuracaoContrato(this.$resource),

      abrirFiltro: false,
      ordenacaoCampos: null,
      carregouFiltrosPadroes: false,
      idContrato: null,
      status: null,
      filtroPadrao: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    carregouMetadados() {
      return this.getAcaoMetadado && this.getContratoMetadado;
    },
    metadadosAgrupado() {
      if (!this.carregouMetadados) {
        return {};
      }

      const metadados = { ...this.getContratoMetadado };
      metadados.dicionario = { ...this.getAcaoMetadado.dicionario };
      Object.assign(metadados.dicionario, this.getContratoMetadado.dicionario);

      metadados.mapaCamposDinamicos = { ...this.getAcaoMetadado.mapaCamposDinamicos };
      Object.assign(metadados.mapaCamposDinamicos, this.getContratoMetadado.mapaCamposDinamicos);

      metadados.mapaEntidades = {};
      Object.assign(metadados.mapaEntidades, this.getContratoMetadado.mapaEntidades);

      Object.keys(this.getAcaoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getAcaoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
      });

      Object.keys(this.getContratoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getContratoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
      });

      if (metadados.cabecalho) {
        metadados.cabecalho.concat(...this.getAcaoMetadado.cabecalho);
      } else {
        metadados.cabecalho = this.getAcaoMetadado.cabecalho;
      }
      return metadados;
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro({ tipo: 'CONTRATO_FECHAMENTO' })
        .then((res) => {
          this.ordenacaoCampos = res.data;
        }).catch((err) => {
          this.$toast(err);
        });
    },
    aplicarFiltros(filtrosAplicados) {
      if (!this.$refs.form.validate() || !filtrosAplicados
          || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (this.idContrato) {
        filtrosAplicados.cod_contrato = this.idContrato;
      }
      filtrosAplicados.status = [this.status];
      filtrosAplicados.id_grupo_fornecedor = filtrosAplicados.grupo_fornecedor;
      this.$emit('ApuracaoContratoCancelarLoteFiltros__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && (filtrosAplicados.data_inicio_apuracao
          || filtrosAplicados.data_fim_apuracao)) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio_apuracao, 'DD-MM-YYYY');
        dtFinal = this.moment(filtrosAplicados.data_fim_apuracao, 'DD-MM-YYYY');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_apuracao && !filtrosAplicados.data_fim_apuracao)
            || (!filtrosAplicados.data_inicio_apuracao && filtrosAplicados.data_fim_apuracao)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    buscarDataApuracoes() {
      const params = {
        status: this.status,
      };
      if (this.idContrato) {
        params.idContrato = this.idContrato;
        this.filtroPadrao.cod_contrato = parseInt(this.idContrato, 10);
      }
      return this.apuracaoContratoResource.buscarDataApuracaoAcaoPorStatus(params)
        .then((response) => {
          this.filtroPadrao.data_inicio_apuracao = response.data.dataInicioApuracao;
          this.filtroPadrao.data_fim_apuracao = response.data.dataFimApuracao;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherFiltroPadrao() {
      Promise
        .all([
          this.buscarDataApuracoes(),
        ])
        .then(() => {
          this.carregouFiltrosPadroes = true;
        });
    },
    preparaCamposFormulario() {
      if (this.idContrato) {
        this.camposFormulario.padrao.forEach((c) => {
          if (c.labelCampo === 'cod_contrato') {
            c.desAtributos = { desabilitado: true };
          }
        });
      }
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
    this.status = this.$router.currentRoute.params.status || 'AGUARDANDO_APURACAO';
    this.preencherFiltroPadrao();
    this.preparaCamposFormulario();
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
};
</script>

<style scoped>

</style>
