<template>
  <v-data-table
    v-model="apuracoesSelecionadas"
    show-select
    :headers="headers"
    item-key="idApuracao"
    :items="apuracoes"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50],
    }"
    class="elevation-0"
    :no-data-text="$t('label.tabela_sem_conteudo')">

    <template v-slot:item.idAcao="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ item.idAcao }}</span>
        </template>
        <span>{{ item.descricaoAcao }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.dataInicio="{ item }">
      {{ `${converterMesAno(item.dataInicio)} ${$tc('label.a', 2)} ${converterMesAno(item.dataFim)}`}}
    </template>

    <template v-slot:item.status="{ item }">
      {{ retornaStatusFormatado(item.status) }}
    </template>

    <template v-slot:item.vlrRealizado="{ item }">
      {{ item.vlrRealizado === 0 ? '' : item.calculoApuracao === 'SELLIN_VOLUME' || item.calculoApuracao === 'SELLOUT_VOLUME' ? item.vlrRealizado : getMoney(item.vlrRealizado) }}
    </template>

    <template v-slot:item.vlrAPagar="{ item }">
      {{ getMoney(item.vlrAPagar) }}
    </template>

  </v-data-table>
</template>

<script>
import { buscarApuracaoPorContrato } from '../../../common/resources/apuracao/apuracao-contrato';
import {
  convertToMoney, convertToPercent, getPercent, convertToNumberFormat, getMoney,
} from '../../../common/functions/helpers';
import exportacao from '../../../common/functions/exportacao';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import PopoverLista from '../../../shared-components/PopoverLista';

export default {
  name: 'ApuracaoContratoCancelarLoteIndustriaTabela',
  components: { InputMoney, PopoverLista },
  props: {
    idContrato: {
      type: Number,
    },
    visaoStatus: {
      type: Boolean,
      default: false,
    },
    filtros: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      configuracao: {},
      esperar: false,
      apuracoes: [],
      headers: [],
      apuracoesSelecionadas: [],
      campoPopover: [
        {
          label: this.$tc('label.ipi', 1),
          campo: 'ipi',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.icms', 1),
          campo: 'icms',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.pis_cofins', 1),
          campo: 'pisCofins',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.devolucao', 1),
          campo: 'devolucao',
          formatarCampos: getMoney,
        },
      ],
    };
  },
  watch: {
    apuracoesSelecionadas(val) {
      this.$emit('ApuracaoContratoCancelarLoteIndustriaTabela__apuracoesSelecionadas', val,
        this.idContrato);
    },
    idContrato() {
      this.carregarConfiguracaoContrato();
    },
  },
  computed: {
    cancelaPagamento() {
      const { passo1 } = this.configuracao;
      return passo1 && passo1.fluxo
          && passo1.fluxo.endsWith('LIQUIDACAO');
    },
    verbaPercentual() {
      if (this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
  },
  methods: {
    getPercent,
    getMoney,
    convertToMoney,
    convertToNumberFormat,
    convertToPercent,
    atualizaHeaders() {
      this.headers = [
        { text: this.$tc('label.cod_acao', 1), value: 'idAcao', sortable: false },
        { text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', sortable: false },
        { text: this.$tc('label.tipo_acao', 1), value: 'tipo', sortable: false },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
        { text: this.$tc('label.periodo_apuracao', 1), value: 'dataInicio', sortable: false },
        { text: this.$tc('label.valor_pagar', 1), value: 'vlrAPagar', sortable: false },
        { text: this.$tc('label.realizado', 1), value: 'vlrRealizado', sortable: false },
      ];
    },
    exportar(item) {
      const params = {
        id_apuracao: item.idApuracao,
      };
      exportacao.exportar(null, 'notas_sellin_memoria_apuracao', this, params);
    },
    exibirMeta(valor, calculoApuracao) {
      if (calculoApuracao === 'SELLIN_VOLUME' || calculoApuracao === 'SELLOUT_VOLUME') {
        return this.convertToNumberFormat(valor);
      }
      return this.convertToMoney(valor);
    },
    exibirPagamento(valor, formaBonificacao) {
      if (formaBonificacao === 'PERCENTUAL') {
        return this.convertToPercent(valor);
      }
      return this.convertToMoney(valor);
    },
    getContrato() {
      return {
        id: this.idContrato,
        fechamentoParcial: this.fechamentoParcial,
        cancelaPagamento: this.cancelaPagamento,
        apuracoes: this.apuracoes,
      };
    },
    getApuracoes() {
      return this.apuracoes;
    },
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    filtrar() {
      if (this.esperar) return;

      this.esperar = true;
      setTimeout(() => {
        this.buscarApuracoes();
      }, 5E2);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    retornaStatusFormatado(status) {
      switch (status) {
        case 'EM_CADASTRO':
          return 'Em cadastro';
        case 'FINALIZADO':
          return 'Finalizado';
        case 'APURACAO_PREVIA':
          return 'Apuração Prévia';
        case 'AGUARDANDO_APURACAO':
          return 'Aguardando Apuração';
        case 'CANCELADO':
          return 'Cancelado';
        default:
          return '';
      }
    },
    getMetasIntervalo(item) {
      return item.metasIntervalo;
    },
    buscarApuracoes() {
      const params = {
        cod_contrato: this.idContrato,
        id_grupo_fornecedor: this.filtros.id_grupo_fornecedor,
        numeroPagina: this.filtros.numeroPagina,
        tamanhoPagina: this.filtros.tamanhoPagina,
      };

      if (this.visaoStatus) {
        params.status = this.filtros.status;
      } else {
        params.status = ['AGUARDANDO_APURACAO', 'APURACAO_PREVIA'];
      }

      return buscarApuracaoPorContrato(params, this.$resource)
        .then((response) => {
          this.apuracoes = response.data;
          this.pararEsperar();
        })
        .catch((err) => {
          this.$error(this, err);
          this.pararEsperar();
        });
    },
    carregarConfiguracaoContrato() {
      if (!this.idContrato) return;
      this.configuracaoResource.buscarConfigSnapshot({ idContrato: this.idContrato })
        .then((res) => {
          this.configuracao = res.data;
          this.filtrar();
          this.atualizaHeaders();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    converterMesAno(val) {
      this.moment.locale('pt-BR');
      return this.moment(val).format('MMM/YY');
    },
  },
  mounted() {
    this.carregarConfiguracaoContrato();
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
