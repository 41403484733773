var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"show-select":"","headers":_vm.headers,"item-key":"idApuracao","items":_vm.apuracoes,"footer-props":{
    itemsPerPageOptions: [10, 25, 50],
  },"no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.idAcao",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.idAcao))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.descricaoAcao))])])]}},{key:"item.dataInicio",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.converterMesAno(item.dataInicio)) + " " + (_vm.$tc('label.a', 2)) + " " + (_vm.converterMesAno(item.dataFim))))+" ")]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.retornaStatusFormatado(item.status))+" ")]}},{key:"item.vlrRealizado",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.vlrRealizado === 0 ? '' : item.calculoApuracao === 'SELLIN_VOLUME' || item.calculoApuracao === 'SELLOUT_VOLUME' ? item.vlrRealizado : _vm.getMoney(item.vlrRealizado))+" ")]}},{key:"item.vlrAPagar",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrAPagar))+" ")]}}]),model:{value:(_vm.apuracoesSelecionadas),callback:function ($$v) {_vm.apuracoesSelecionadas=$$v},expression:"apuracoesSelecionadas"}})}
var staticRenderFns = []

export { render, staticRenderFns }